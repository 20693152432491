import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Card from './common/Card'
import theme from './theme'
import validator from 'validator'

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import  { Send } from "@material-ui/icons"
import { ReCaptcha } from 'react-recaptcha-google'


const Spacer = styled.div`
    margin: 0 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;

    >:first-child{
        margin-top: 2rem;
    }

    > div {
        margin-bottom: 1rem;
    }

    > button {
        max-width: 12rem;
        margin: 0 auto;
        margin-bottom: 1rem;
    }
`

const useStyles = makeStyles(() => ({
    textField: {
      marginLeft: '1rem',
      marginRight: '1rem',
      '& label.Mui-focused': {
        color: theme.color.primary,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: theme.color.primary,
      },
      '& .MuiFormLabel-root':{
          color: theme.color.font
      },
      '& .MuiOutlinedInput-root': {
        color: theme.color.font,
        '& fieldset': {
        borderColor: theme.color.primary,
        },
        '&:hover fieldset': {
        borderColor: theme.color.primary,
        },
        '&.Mui-focused fieldset': {
        borderColor: theme.color.primary,
        },
      },
    },
    button: {
      margin: '1rem',
      color: theme.color.font,
      backgroundColor: theme.color.primary,
      '&:hover':{
        backgroundColor: theme.color.primary,
      }
    },
    extendedIcon: {
      marginRight: '1rem',
    },
  }));

const Contact = () => {
    const [ from, setFrom ] = useState('')
    const [ body, setBody ] = useState('')
    const [ fromError, setFromError ] = useState('')
    const [ bodyError, setBodyError ] = useState('')
    const [ captcha, setCaptcha ] = useState(null)
    const [ captchaLoaded, setCaptchaLoaded ] = useState(false)
    const [ captchaToken, setCaptchaToken ] = useState(null)
    const [ sending, setSending ] = useState(false)
    const [ sent, setSent ] = useState(false)
    const classes = useStyles()

    useEffect(() => {
        if (captcha && captchaLoaded) {
            setCaptchaLoaded(true)
            captcha.reset();
            captcha.execute();
        }
    }, [captchaLoaded, setCaptchaLoaded, captcha])

    const onLoadRecaptcha = () => {
        if (captcha) {
            captcha.reset();
            captcha.execute();
        }
    }

    const verifyCallback = (recaptchaToken) => { 
        setCaptchaToken(recaptchaToken)
    }
    
    
    const submitForm = () => {
        const emailValid = validator.isEmail(from)
        const bodyValid = body.length > 10
        if (!emailValid) setFromError('Invalid email')
        if (!bodyValid) setBodyError('Invalid body')
        if (emailValid && bodyValid && captchaToken){
            setFromError('')
            setBodyError('')
            setSending(true)
            fetch(
                "https://api.aliceokeefe.science/rest/prod/contact", {
                    method:'POST',
                    headers:{
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        from,
                        body,
                        captchaToken
                    })
                })
                .then(()=>{
                    setSending(false)
                    setSent(true)
                })
                .catch(err=>{
                    setSending(false)
                    setSent(false)
                    console.error(err)
                })
        }
    }

    return (
        <Card style={{ marginBottom:'2rem' }}>
            <Spacer>
            <TextField
                id="from"
                label={ fromError || "Your Email"}
                className={classes.textField}
                value={from}
                onChange={event => setFrom(event.target.value)}
                margin="normal"
                variant="outlined"
                error={!!fromError}
            />
            <TextField
                id="from"
                label={ bodyError || "Your Message"}
                className={classes.textField}
                value={body}
                onChange={event => setBody(event.target.value)}
                margin="normal"
                variant="outlined"
                multiline
                rows={10}
                error={!!bodyError}
            />
            <ReCaptcha
                ref={el => setCaptcha(el)}
                size="invisible"
                render="explicit"
                sitekey="6LcmHrsUAAAAAAfMqz3vWL1KpUAKWhRDpx_TEXqJ"
                onloadCallback={onLoadRecaptcha}
                verifyCallback={verifyCallback}
                theme="dark"
            />
            <Fab
              variant="extended"
              size="medium"
              className={classes.button}
              aria-label="add"
              onClick={submitForm}
              disabled={!captchaToken || sending || sent}
            >
              <Send className={classes.extendedIcon}/>
              {
                  sending 
                ? 'Sending...'
                : sent
                ? 'Sent!'
                : 'Send'
              }
            </Fab>
            </Spacer>
        </Card>
    )
}

export default Contact