import React from 'react'
import Particles from 'react-particles-js';
import { useWindowSize } from './utils'
import theme from './theme';


export default () => {
    const size = useWindowSize()
    return <Particles 
        style={{
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: -1,
        }}
        params={{
            particles:{
                number:{
                    value: Math.ceil(size.width*size.height/10000)
                },
                move:{
                    speed: 0.2
                },
                line_linked:{
                    distance: 150,
                    color: theme.color.accent
                },
                color: {
                    value: [theme.color.primary, theme.color.accent, theme.color.cards]
                },
            },
        }}
    />
}