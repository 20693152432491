import styled from 'styled-components'


export default styled.h2`
    display: block;
    margin-bottom: 1rem;
    margin-top: 1rem;
    margin-left: 1rem;
    font-size: 2rem;
    color: ${({ theme }) => theme.color.primary};
    font-family: ${({ theme }) => theme.typography.fontFamily };
`