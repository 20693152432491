import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Link } from "@material-ui/icons"



export const SmallCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 0.5rem);
    padding: 0.5rem;
    box-shadow: ${({ theme}) => theme.boxShadow.s };
    background-color: ${({ theme}) => theme.color.cards };
    transition: all 200ms;

    cursor: ${({ linked }) => linked ? 'pointer' : 'auto'};   
    :hover{    
        ${({ linked, theme }) => linked 
        ? `
            box-shadow: ${theme.boxShadow.l};
            transform: scale(1.05);
        ` : null};   
    }
`

const H2 = styled.h2`
    font-size: 1rem;
    margin: 0;
    display: inline-block;
`

const H3 = styled.h2`
    font-size: 0.7rem;
    color: ${({ theme }) => theme.color.fontSmall};
    margin: 0;
    text-transform: uppercase;
`

const SmallCardHeadingDiv = styled.div`
    display: flex;
    width: 100%;
`
const LinkPositioner = styled.span`
    margin-left: auto;
    color: ${({ theme }) => theme.color.fontSmall};
`

const renderExperienceCard = ({ index, title, time, description, link }) => <SmallCard 
    key={index}
    onClick={()=> link ? window.open(link, '_blank') : null}
    linked={!!link}
>
    <SmallCardHeadingDiv>
        <H2>{title}</H2>
    { link && <LinkPositioner><Link/></LinkPositioner> }
    </SmallCardHeadingDiv>
    <H3>{time}</H3>
    <p>{description}</p>


</SmallCard>


const Experience = ({ data = [] }) => <Fragment>
    {data.map((data, index) => renderExperienceCard({index, ...data}))}
</Fragment>

export default Experience