import React from "react"
import styled from "styled-components"
import FollowAt from "react-social-media-follow"
import LIB from "./LI-In-Bug.png"
import RG from "./resarchgate.png"

const SocialsBlock = styled.div`
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    padding: 0.5rem 1rem;
    padding-bottom: calc(0.5rem + 5px);
    transition: all 0.5s ease
    box-shadow: ${({ theme, scrollPosition }) => scrollPosition > 50 ? theme.boxShadow.s : ""};
    
    @media (max-width:800px){
        flex-direction: column;
        top: 100px;
        box-shadow: ${({ theme }) => theme.boxShadow.s};

        > div {
            display: flex;
            flex-direction: column;
        }
    }

`

const Img = styled.img`
    height: 2em;
    margin-left: 5px;
`

const links = [
    'https://twitter.com/okeefealice',
    'https://www.instagram.com/aliceo.keefe/'
]

const Socials  = ({ scrollPosition }) => <SocialsBlock scrollPosition={scrollPosition}>
    <FollowAt links = {links} spacing="5px" />
    <a href="https://www.linkedin.com/in/alice-o-keefe-479255151/" target="_blank" rel="noopener noreferrer">
        <Img alt="LinkedIn" src={LIB} />
    </a>
    <a href="https://www.researchgate.net/profile/Alice-Okeefe-2" target="_blank" rel="noopener noreferrer">
        <Img alt="LinkedIn" src={RG} />
    </a>
</SocialsBlock>

export default Socials