import React from 'react';
import styled from 'styled-components'


const Nav = styled.div`
  width: 100%;
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
  background: ${({ theme }) => theme.color.primary};

  @media(max-width:800px){
    >:nth-child(2n+1){
      display: block; 
    }
    >:nth-child(3){
      font-size: 1rem;
    }
  }
`

const H1 = styled.h1`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 2rem;
  display: inline-block;
  color: ${({ theme }) => theme.color.accent };
  margin: 0;
`
const H1Hide = styled(H1)`
  @media(max-width:800px){
    display: none ;
  }
`
const H1Secondary = styled(H1)`
  color: ${({ theme }) => theme.color.fontSmall };
`
const H1Hero = styled(H1)`
  color: ${({ theme }) => theme.color.fontSmall };
`

export default () => {

  return (
    <Nav>
      <H1Hero>Alice O'Keefe </H1Hero>
      <H1Hide>&nbsp;|&nbsp;</H1Hide>
      <H1Secondary>Science Communicator</H1Secondary>
    </Nav>
  );
}