import React from 'react'
import styled from 'styled-components'
import Card from './common/Card'



const Headshot = styled.img`
    height: 30vh;
    max-width: 100%;
    margin-right: 1rem;
    align-self: baseline;
    @media (max-width:580px){
        align-self: center;
    }
`

const Spacer = styled.div`
    margin: 0 1rem;
`

const Header = ({ data:dataList }) => {
    const data = dataList && dataList.length > 0 ? dataList[0] : {}

    return <Card>
        <Headshot alt={data?.image?.fields?.title} src={data?.image?.fields?.file?.url}/>
        <Spacer>
            <h2>{data?.heading}</h2>
            <p>{data?.paragraph1}</p>
            <p>{data?.paragraph2}</p>
        </Spacer>
    </Card>
}

export default Header