import React, { Fragment, useEffect, useRef, useState } from 'react'
import AppBar from './AppBar'
import Header from './Header'
import styled from 'styled-components'
import Experience from './Experience'
import Media from './Media'
import SectionHeading from './common/SectionHeading'
import { GlobalStyle } from './theme'
import Background from './Background'
import Contact from './Contact'
import { loadReCaptcha } from 'react-recaptcha-google'
import Socials from './Socials'
import Publications from './Publications'
import StudentResources from './StudentResources'
const contentful = require('contentful')

const SingleColumn = styled.div`
  color: ${({ theme }) => theme.color.font};
  font-family: ${({ theme }) => theme.typography.fontFamily };
  width: 60%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  
  >div{
    border-left: 0.6rem ${({ theme }) => theme.color.primary} solid;
    width: calc(100% - 0.6rem);
  }

  @media ( max-width: 1200px) {
    width: 75%;
  }
  @media ( max-width: 700px) {
    width: 95%;
  }
  
  >:not(:last-child){
    margin-bottom: 2rem;
  }
`

const DoubleColumns = styled.div`
  color: ${({ theme }) => theme.color.font};
  font-family: ${({ theme }) => theme.typography.fontFamily };
  width: 60%;
  margin: 0 auto;

  display: grid;
  grid-template-columns: auto auto;
  gap: 2rem 2rem;

  
  @media ( max-width: 1200px) {
    width: 75%;
  }
  @media ( max-width: 700px) {
    width: 95%;
    grid-template-columns: auto;
  }
  

  >div{
    width: calc(100% - 1.3rem);
  }

  
  @media ( min-width: 700px) {
    >:nth-child(2n+1){
      border-left: 0.6rem ${({ theme }) => theme.color.primary} solid;
    }
    >:nth-child(2n){
      border-right: 0.3rem ${({ theme }) => theme.color.primary} solid;
    }
  }
  @media ( max-width: 700px) {
    >div {
      border-left: 0.6rem ${({ theme }) => theme.color.primary} solid;
    }
  }
`

const useContentful = () => {
  const [data, setData] = useState({})

  const client = useRef(
    contentful.createClient({
      space: 'it05vq3vg1x8',
      environment: 'master',
      accessToken: "_GrS-7nCsN1dV0NUDHCwNjijC6qdG4S7Oe5aecARnLM"
    })
  )

  useEffect(() => {
    const getData = async () => {
      let freshData = {}
      const response = await client.current.getEntries()
      response.items.forEach(item => {
        const type = item?.sys?.contentType?.sys?.id
        if(type) {
          if (freshData[type]) freshData[type].push(item.fields)
          else freshData[type] = [item.fields]
        }
      })

      // Sort data
      Object.keys(freshData).forEach(key => {
        freshData[key] = freshData[key].sort(function(a, b) {
          if ((a.priority || 999999) < (b.priority || 999999)) return -1;
          if ((a.priority || 999999) > (b.priority || 999999)) return 1;
          return 0;
        })
      })

      setData(freshData)
    }
    getData()

  }, [])

  return data

}




const App = () =>  {
  useEffect(loadReCaptcha, [])
  const [scrollPosition, setScrollPosition] = useState(0)
  useEffect(() => {
    window.addEventListener('scroll', e => setScrollPosition(e.target.documentElement.scrollTop));
  },[]);

  const contentfulData = useContentful()

  return (
    <Fragment>
      <Background />
      <GlobalStyle />
      <AppBar />
      <SingleColumn>
        <Header data={contentfulData?.header}/>
      </SingleColumn>
      <SectionHeading>Publications</SectionHeading>
      <DoubleColumns>
        <Publications data={contentfulData?.publication}/>
      </DoubleColumns>
      <SectionHeading>Educational Resources</SectionHeading>
      <DoubleColumns>
        <StudentResources data={contentfulData?.studentResouce}/> {/* The contentful type name is a typo :(  */}
      </DoubleColumns>
      <SectionHeading>Media </SectionHeading>
      <SingleColumn>
        <Media data={contentfulData?.youTubeVideo}/>
      </SingleColumn>
      <SectionHeading>Experience</SectionHeading>
      <DoubleColumns>
        <Experience data={contentfulData?.experience} />
      </DoubleColumns>
      <SectionHeading>Contact</SectionHeading>
      <SingleColumn>
        <Contact />
      </SingleColumn>
      <Socials scrollPosition={scrollPosition} />
    </Fragment>
  );
}


export default App;
