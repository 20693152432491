import { createGlobalStyle } from 'styled-components'

export default {
    color: {
        primary: "#729d39",
        accent: "#36622b",
        cards:"#b4cf6b",
        font: "rgba(0,0,0, 0.9)",
        fontSmall: "rgba(0, 0, 0, 0.5)"
    },
    typography: {
        fontFamily: "'Quicksand', sans-serif",
    },
    boxShadow: {
        s: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
        l: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)"
    }
}

export const GlobalStyle = createGlobalStyle`
  html {
    @media(min-width:1500px){
        font-size: 25px;
    }
    @media(min-width:3000px){
        font-size: 60px;
    }
  }
`


