import React, { Fragment } from 'react'
import styled from 'styled-components'
import { CloudDownload } from '@material-ui/icons'
import { SmallCard } from './Experience'


const Col = styled.div`
    margin: 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

const H2 = styled.h2`
    font-size: 1rem;
    margin: 0;
    display: inline-block;
`

const H3 = styled.h2`
    font-size: 0.7rem;
    color: ${({ theme }) => theme.color.fontSmall};
    margin: 0;
    text-transform: uppercase;
`

const StyledLink = styled.a`
    display: flex;
    font-size: 1rem;
    margin: 0;
    margin-right: auto;
    color: ${({ theme }) => theme.color.fontSmall};
    text-decoration: none;
    cursor: pointer;

    > svg {
        margin-right: 10px;
    }
`



const Pdf = ({ data: {title, publicationDate, head, pdf }}) => {

    return <SmallCard>
        <Col>
            <H2>{title}</H2>
            <H3>{publicationDate}</H3>
            <p>{head}</p>
            <StyledLink href={pdf?.fields?.file?.url} target="_blank" rel="noopener noreferrer"><CloudDownload />click here to download...</StyledLink>
        </Col>
    </SmallCard>
}


const StudentResources = ({ data=[] }) => {
    return <Fragment>
        {data.map((data, index) =>  <Pdf key={index} data={data} />)}
    </Fragment>
}

export default StudentResources