import React, { Fragment } from 'react'
import styled from 'styled-components'
import Card from './common/Card'
import { useWindowSize } from './utils'


const H2 = styled.h2`
    font-size: 1rem;
    margin: 0;
    margin-right: auto;
`

const H3 = styled.h2`
    font-size: 0.7rem;
    color: ${({ theme }) => theme.color.fontSmall};
    margin: 0;
    margin-right: auto;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
`

const Col = styled.div`
    margin: 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`

const YouTubeVideo = ({ data: {youtubeVideoId, title, publicationDate }}) => {
    const size = useWindowSize()
    let multiplier = 0.5
    if (size.width <= 1000) multiplier = 0.6
    if (size.width <= 700) multiplier = 0.8
    const width = Math.ceil(multiplier*size.width)
    const height = Math.ceil((9/16)*multiplier*size.width)

    return <Card>
        <Col>
            <H2>{title}</H2>
            <H3>{publicationDate}</H3>
            <iframe title={`${title}-iframe`} type="text/html" width={width} height={height} src={`https://www.youtube.com/embed/${youtubeVideoId}?enablejsapi=1&origin=http://aliceokeefe.science`} frameBorder={0} />
        </Col>
    </Card>
}


const Media = ({ data =[] }) => <Fragment>
    { data.map( (data, index) => <YouTubeVideo data={data} key={index} />)}
</Fragment>
    

export default Media