import styled from 'styled-components'


export default styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: ${({ theme}) => theme.boxShadow.s };
    background-color:${({ theme}) => theme.color.cards };

    @media (max-width:580px) {
        flex-direction: column;
    }
`